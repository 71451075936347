import React, { FC } from "react"
import {
  Box,
  TextField,
  FormControl,
  Button,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  ListSubheader,
  makeStyles
} from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { ICommunity } from "models/MCP/ICommunity"
import { Department } from "models/MCP/Department"
import { Delete, Save } from "@material-ui/icons"
import { IncomingJobMapInterface, MapTypeEnum } from "models/MCP/DenaliMaps"
const useStyles = makeStyles({
  saveButton: {
    marginLeft: "0.5em"
  },
  existingIdRow: {
    backgroundColor: "auto"
  },
  newIdRow: {
    backgroundColor: "#ffe345"
  }
})

interface IncomingJobMapRowProps {
  mapping: IncomingJobMapInterface
  index: number
  communityFilteredOptions: any[]
  modified: boolean
  type: any
  communities: ICommunity[] | undefined
  filteredByCompany: () => boolean
  onChange: (key: any, mapping: IncomingJobMapInterface) => void
  onDelete: (key: any, deletedMapping: IncomingJobMapInterface) => void
  onSave: (key: any, mapping: IncomingJobMapInterface) => void
}

export const IncomingJobMapRow: FC<IncomingJobMapRowProps> = ({
  mapping,
  index,
  communityFilteredOptions,
  communities,
  modified,
  type,
  filteredByCompany,
  onChange,
  onDelete,
  onSave
}) => {
  const classes = useStyles()

  const labels = {
    customer: ["Job Code", "Job Code", "Pay Code", "FLSA Code", "Facility Id"],
    companyCode: ["Employee Job Code", "Punch Job Code", "Punch Pay Code", "FLSA Type", "Community"]
  }

  const listGroup = (department: Department, type: MapTypeEnum) => {
    let jobFunctions
    if (type === MapTypeEnum.FlsaCode) {
      return (
        <MenuItem key={`${index}.${department.id}`} value={department.id}>
          {department.name}
        </MenuItem>
      )
    } else {
      jobFunctions = department.jobFunctions.map((jobFunction) => (
        <MenuItem key={`${index}.${department.id}.${jobFunction.id}`} value={jobFunction.id}>
          {jobFunction.name}
        </MenuItem>
      ))
      const header = <ListSubheader key={`${index}.${department.id}`}>{department.name}</ListSubheader>
      return [header, jobFunctions]
    }
  }

  return (
    <React.Fragment key={index}>
      <Grid item xs={4} id="parent" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
        <Box style={styles.box} id="customerCode">
          <TextField
            value={mapping.customerCode}
            onChange={(e) => {
              let updatedMapping = { ...mapping, customerCode: e.target.value }
              onChange(index, updatedMapping)
            }}
            label={`Customer ${labels.customer[type]}`}
            variant="outlined"
          ></TextField>
        </Box>
      </Grid>
      <Grid item xs={4} className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
        <Box style={styles.box} id="mappedValue">
          <FormControl fullWidth variant="outlined">
            <InputLabel id={`mappedValue.${labels.companyCode[type]}`}>{labels.companyCode[type]}</InputLabel>
            <Select
              labelId="mappedValueLabelId"
              id="mappedValueId"
              fullWidth
              value={mapping.tableId}
              defaultValue=""
              onChange={(e) => {
                let updatedMapping = { ...mapping, tableId: String(e.target.value) }
                onChange(index, updatedMapping)
              }}
              label={labels.companyCode[type]}
            >
              {!filteredByCompany()
                ? communityFilteredOptions?.map((selectorItem) => listGroup(selectorItem, type))
                : communities?.map((community) => (
                    <MenuItem key={`${index}.${community.id}`} value={community.id}>
                      {community.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={styles.box}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => onSave(index, mapping)}
            className={classes.saveButton}
          >
            <Save />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => onDelete(index, mapping)}
            className={classes.saveButton}
          >
            <Delete />
          </Button>
        </Box>
      </Grid>
    </React.Fragment>
  )
}
