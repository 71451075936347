import { getEnvVarByName } from "../../util.js"

export const REACT_APP_API_BASE = `${getEnvVarByName("REACT_APP_API_BASE")}`

declare global {
  interface Window {
    REACT_APP_SCHEDULE_BASE: string
  }
}

export const SCHEDULE_API_BASE = `${getEnvVarByName("REACT_APP_SCHEDULE_BASE")}`
export const MCP_API_BASE = getEnvVarByName("MCP_API_BASE") ?? getEnvVarByName("REACT_APP_MCP_API_BASE")
